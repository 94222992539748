import React from 'react';
// SemanticUI-free pre-@plone/components

import Container from '@kitconcept/volto-light-theme/components/Atoms/Container/Container';
import { Image } from '@plone/volto/components';
import { FormattedMessage } from 'react-intl';

import FooterLinks from '@plonegovbr/volto-network-block/components/FooterLinks/FooterLinks';

import unicatadoresLogo from './unicatadores.png';
import aliancaLogo from './alianca.png';
import gaiaLogo from './gaia.png';
import redlatinaLogo from './redlatina.png';
import simplesLogo from './logo-simplesconsultoria.svg';

const footerMemberships = [
  {
    id: 'alianca',
    title: 'Aliança Global de Catadores',
    href: 'https://globalrec.org/',
    name: aliancaLogo,
  },
  {
    id: 'gaia',
    title: 'Aliança Global por Alternativas à Incineração',
    href: 'https://no-burn.org/',
    name: gaiaLogo,
  },
  {
    id: 'redlatina',
    title: 'Red Latinoamericana de Recicladores',
    href: 'https://www.redrecicladores.net',
    name: redlatinaLogo,
  },
];

const Networks = () => {
  return footerMemberships.map(function (item, i) {
    return (
      <a
        href={item.href}
        title={item.title}
        key={i}
        className={'network'}
        target={'_blank'}
        rel={'noreferrer'}
      >
        <Image
          className={'networkLogo'}
          src={item.name}
          alt={item.title}
          title={item.title}
        />
      </a>
    );
  });
};

/**
 * Component to display the footer.
 * @function Footer
 * @returns {string} Markup of the component
 */
const Footer = () => {
  return (
    <footer id="footer">
      <Container className="footer">
        <div className="content-container">
          <div className="address-contact-container">
            <div className="ecosistema footer-column">
              <p className="headline">Ecosistema</p>
              <p>
                <a
                  href={'https://unicatadores.org.br/'}
                  title={'Unicatadores'}
                  className={'ecoLink'}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  <Image
                    className={'ecoLogo'}
                    src={unicatadoresLogo}
                    alt={'Logomarca da Unicatadores'}
                    title={'Unicatadores'}
                  />
                </a>
              </p>
            </div>
            <div className="address footer-column">
              <p className="headline">Endereço</p>
              <address className="addressText">
                Rua 24 de Maio 276 - 5ᵒ Andar <br />
                República - São Paulo - SP <br />
                01041-905 <br />
                <FormattedMessage id="phone" defaultMessage="Fone" /> +55(11)
                3399-3475 <br />{' '}
                <FormattedMessage id="email" defaultMessage="E-mail" />{' '}
                contato@mncr.org.br <br />
              </address>
            </div>
            <div className="networks footer-column">
              <p className="headline">MNCR é membro</p>
              <Networks />
            </div>
            <div className="socialNetworks footer-column">
              <p className="headline">Redes</p>
              <FooterLinks animate={false} align={'left'} />
            </div>
          </div>
        </div>
      </Container>
      <Container className="poweredBy">
        <div className={'signature'}>
          <a
            href={
              'https://www.simplesconsultoria.com.br?mtm_campaign=case&mtm_kwd=mncr'
            }
            title={'Simples Consultoria'}
            className={'signatureLink'}
            target={'_blank'}
            rel={'noreferrer'}
          >
            <Image
              className={'signatureLogo'}
              src={simplesLogo}
              alt={'Logo da Simples Consultoria'}
              title={'Simples Consultoria'}
            />
          </a>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
