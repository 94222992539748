import { cloneDeep } from 'lodash';

// Document by Line
import radioSVG from '@plone/volto/icons/radio.svg';
import DocumentByLineEditBlock from './components/Blocks/DocumentByline/Edit';
import DocumentByLineViewBlock from './components/Blocks/DocumentByline/View';

// Listing variants
import EventsSummary from './components/Blocks/Listing/EventsSummary';

import EventView from './components/Views/EventView';

// Image variants
import ImageBlockView from './components/Blocks/Image/View';
import { ImageView } from './components/Blocks/Image/ImageView';
import { ImageVertical } from './components/Blocks/Image/ImageVertical';

const BG_COLORS = [
  { name: 'transparent', label: 'Transparent' },
  { name: 'grey', label: 'Cinza' },
  { name: 'blue', label: 'Azul' },
  { name: 'green', label: 'Verde' },
];

const applyConfig = (config) => {
  config.settings = {
    ...config.settings,
    isMultilingual: false,
    supportedLanguages: ['pt-br'],
    defaultLanguage: 'pt-br',
  };
  // Content Views
  config.views.contentTypesViews = {
    ...config.views.contentTypesViews,
    Event: EventView,
  };
  // Novos blocos
  config.blocks.blocksConfig.documentbyline = {
    id: 'documentbyline',
    title: 'Document byline',
    icon: radioSVG,
    group: 'text',
    view: DocumentByLineViewBlock,
    edit: DocumentByLineEditBlock,
    restricted: true,
    mostUsed: false,
    sidebarTab: 0,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.listing.variations = [
    ...config.blocks.blocksConfig.listing.variations,
    {
      id: 'events-summary',
      title: 'Events Summary',
      template: EventsSummary,
    },
  ];

  // Adiciona cores aos blocos
  [
    '__button',
    'accordion',
    'gridBlock',
    'heading',
    'maps',
    'search',
    'slate',
    'slateTable',
    'slider',
    'teaser',
    'video',
  ].forEach((blockId) => {
    const blocksConfig = config.blocks.blocksConfig;
    const block = blocksConfig[blockId];
    if (block !== undefined) {
      blocksConfig[blockId] = {
        ...blocksConfig[blockId],
        colors: BG_COLORS,
      };
    }
  });

  // Image variations
  const imageVariations = [
    {
      id: 'default',
      isDefault: true,
      title: 'Default',
      template: ImageView,
    },
    {
      id: 'portrait',
      isDefault: false,
      title: 'Portrait',
      template: ImageVertical,
    },
  ];
  config.blocks.blocksConfig.image.variations = imageVariations;
  config.blocks.blocksConfig.image.view = ImageBlockView;

  // Always link to view page
  config.settings.downloadableObjects = [];

  // Configure default blocks for News Item
  config.blocks.initialBlocks = {
    ...config.blocks.initialBlocks,
    'News Item': [
      { '@type': 'title', fixed: true },
      { '@type': 'documentbyline', fixed: true },
    ],
  };

  // Add Blocks to gridBlock and accordionBlock
  // It's important to maintain the chain, and do not introduce pass by reference in
  // the internal `blocksConfig` object, so we clone the object to avoid this.
  const localBlocks = ['image'];
  ['gridBlock', 'accordion'].forEach((blockId) => {
    const block = config.blocks.blocksConfig[blockId];
    if (
      block !== undefined &&
      block.allowedBlocks !== undefined &&
      block.blocksConfig !== undefined
    ) {
      block.allowedBlocks = [...block.allowedBlocks, ...localBlocks];
      localBlocks.forEach((localBlockId) => {
        block.blocksConfig[localBlockId] = cloneDeep(
          config.blocks.blocksConfig[localBlockId],
        );
      });
    }
  });

  return config;
};

export default applyConfig;
