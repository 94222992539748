import React from 'react';
import PropTypes from 'prop-types';
import { ImageView } from './ImageView';
import config from '@plone/volto/registry';

const ImageBody = (props) => {
  const { variation, data } = props;

  const hasType = data.href?.[0]?.['@type'];

  const BodyComponent =
    (config?.getComponent &&
      hasType &&
      config.getComponent({ name: 'Image', dependencies: [hasType] })
        .component) ||
    variation?.template ||
    ImageView;

  return <BodyComponent {...props} />;
};

ImageBody.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default ImageBody;
