/**
 * CalendarDate component.
 * @module components/CalendarDate/CalendarDate
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';

const CalendarDate = ({ content, moment: momentlib }) => {
  const intl = useIntl();
  const moment = momentlib.default;
  moment.updateLocale(intl.locale, moment.localeData(intl.locale)._config); // copy locale to moment-timezone
  const isEvent = content['@type'] === 'Event';
  const start = isEvent && moment(content.start);
  return isEvent && start ? (
    <div className={'cal_wrapper'}>
      <div className={'cal_date'}>
        <span className="cal_day">{start.format('DD')}</span>
        <span className="cal_month">{start.format('MMM')}</span>
        <span className="cal_year">{start.format('YYYY')}</span>
      </div>
    </div>
  ) : (
    <div className={'cal_date empty'}> </div>
  );
};

CalendarDate.propTypes = {
  content: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    '@type': PropTypes.string,
  }).isRequired,
};

export default injectLazyLibs(['moment'])(CalendarDate);
