/**
 * Footer component.
 * @module components/theme/DocumentByline/DocumentByline
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import { useIntl, FormattedMessage } from 'react-intl';

const DocumentByline = ({ content, longDisplay, moment: momentlib }) => {
  const intl = useIntl();
  const creators =
    content.creators !== undefined
      ? content.creators?.join(', ')
      : content.Creator;
  const moment = momentlib.default;
  moment.updateLocale(intl.locale, moment.localeData(intl.locale)._config); // copy locale to moment-timezone
  const effective = moment(content.effective).format('DD/MM/YYYY');
  const modified = moment(content.modified).format('DD/MM/YYYY');
  const samedate = effective === modified;
  return longDisplay ? (
    <p className={'documentbyline long'}>
      <span className={'author'}>
        <FormattedMessage
          id="By {author}"
          defaultMessage="{author}"
          values={{ author: creators }}
        />
      </span>{' '}
      <span className={'effective'}>
        <FormattedMessage
          id="Published {date}"
          defaultMessage="Publicado {date}"
          values={{ date: effective }}
        />
      </span>
      {!samedate && (
        <span className={'modified'}>
          {' '}
          —{' '}
          <FormattedMessage
            id="Modified {date}"
            defaultMessage="Última modificação {date}"
            values={{ date: modified }}
          />
        </span>
      )}
    </p>
  ) : (
    <p className={'documentByline short'}>
      <span className={'author'}>
        <FormattedMessage
          id="By {author}"
          defaultMessage="{author}"
          values={{ author: creators }}
        />
      </span>{' '}
      —{' '}
      <span className={'modified'}>
        <FormattedMessage
          id="Modified {date}"
          defaultMessage="Última modificação {date}"
          values={{ date: modified }}
        />
      </span>
    </p>
  );
};

DocumentByline.propTypes = {
  content: PropTypes.shape({
    creators: PropTypes.arrayOf(PropTypes.string),
    effective: PropTypes.string,
    modified: PropTypes.string,
  }).isRequired,
  longDisplay: PropTypes.bool,
};

export default injectLazyLibs(['moment'])(DocumentByline);
