/**
 * View document by line block.
 * @module components/manage/Blocks/DocumenbByLine/View
 */

import React from 'react';
import PropTypes from 'prop-types';

import DocumentByline from '../../DocumentByline/DocumentByline';

/**
 * View document block class.
 * @class View
 * @extends Component
 */
const View = ({ properties }) => (
  <DocumentByline content={properties} longDisplay={true} />
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
