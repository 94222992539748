/**
 * Navigation component.
 * @module components/theme/Logo/Logo
 */
import { injectIntl } from 'react-intl';
import LocalNavigation from '../../../../../components/Navigation/Navigation';

const Navigation = () => {
  return <LocalNavigation />;
};

export default injectIntl(Navigation);
