/**
 * Logo component.
 * @module components/theme/Logo/Logo
 */
import LocalLogo from '../../../../../components/Logo/Logo';

/**
 * Logo component class.
 * @function Logo
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component.
 */
const Logo = () => {
  return <LocalLogo />;
};

export default Logo;
