/**
 * Edit document by line block.
 * @module components/manage/Blocks/DocumenbByLine/Edit
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Edit document block class.
 * @class Edit
 * @extends Component
 */
const Edit = ({ properties }) => (
  <div className={'documentByline edit'}>Document by line</div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Edit.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Edit;
